package view

import Lang
import component.ContactForm
import component.SectionHeading
import csstype.ClassName
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import postEmail
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.key
import react.useState

private val scope = MainScope()

val ContactSection = FC<Props> { props ->
    var contactFormSend by useState(false)
    div {
        id = "contact"
        className = ClassName("section")
        div {
            className = ClassName("split")
            div {
                SectionHeading{
                    title = Lang.getSelected().contact_title
                }
                span { +Lang.getSelected().contact_list_header }
                span {
                    //TODO LIST
                    li {
                        span { +Lang.getSelected().contact_list_item_1 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_2 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_3 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_4 }
                    }
                    li {
                        span { +Lang.getSelected().contact_list_item_5 }
                    }
                }
            }
            div {
                className = ClassName("contact-form")
                if (!contactFormSend) {
                    ContactForm {
                        onSubmit = { email, name, phone, message ->
                            contactFormSend = true
                            scope.launch {
                                postEmail(email, name, phone, message)
                                console.log("sent with result $contactFormSend")
                            }
                        }
                        name = ""
                        email = ""
                        message = ""
                        phone = ""
                        key = ""
                    }
                } else {
                    div {
                        className = ClassName("form-confirmation")
                        h2 {
                            +Lang.getSelected().contact_message
                        }
                    }
                }
            }
        }
    }
}
