package view

import Lang
import component.SectionHeading
import csstype.ClassName
import db.firebase
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span

@Serializable
data class Product(
        val id: Int,
        val name: String,
        val description: String,
        val url: String,
        val image: String,
        val extraText: String,
        val button: String
)

external interface ProductSectionProps : Props {
    var products: List<Product>
}

val ProductSection = FC<ProductSectionProps> { props ->

    div {
        id = "products"
        className = ClassName("listing")
        SectionHeading {
            title = Lang.getSelected().product_title
        }
        div {
            props.products.forEach {
                ProductView {
                    product = it
                }
            }
        }
    }
}

external interface ProductProps : Props {
    var product: Product
}

val ProductView = FC<ProductProps> { props ->
    div {
        className = ClassName("product")
        div {
            className = ClassName("head")
            img {
                alt = props.product.name
                src = props.product.image
            }
        }
        div {
            className = ClassName("description")
            div {
                h3 {
                    +props.product.description
                }
                span {
                    +props.product.extraText
                }
            }
        }
        a {
            if (props.product.url == "#") {
                href = "#contact"
                onClick = {
                    // TODO translation
                    firebase.sendMessageWithContactRequest("Mám zájem se dozvědět více o produktu: ${props.product.name}")
                }
            } else {
                target = AnchorTarget._blank
                href = props.product.url
            }
            className = ClassName("button")
            + props.product.button
        }
    }
}

