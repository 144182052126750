package component

import Lang
import csstype.ClassName
import org.w3c.dom.HTMLFormElement
import react.FC
import react.Props
import react.dom.events.FormEventHandler
import react.dom.html.InputType
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.textarea
import react.useState

external interface ContactFormProps : Props {
    var email: String
    var name: String
    var phone: String
    var message: String
    var onSubmit: (String, String, String, String) -> Unit
}

val ContactForm = FC<ContactFormProps> { props ->
    var email by useState(props.email)
    var name by useState(props.name)
    var phone by useState(props.phone)
    var message by useState(props.message)

    val emailREGEX = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$"

    val submitHandler: FormEventHandler<HTMLFormElement> = {
        it.preventDefault()
        props.onSubmit(email, name, phone, message)
    }


    h3 {
        span { +Lang.getSelected().contact_form_title }
    }
    form {
        onSubmit = submitHandler
        input {
            placeholder = Lang.getSelected().contact_form_name_input_placeholder
            type = InputType.text
            value = name
            required
            onChange = { event ->
                name = event.target.value
            }
        }
        input {
            placeholder = Lang.getSelected().contact_form_phone_input_placeholder
            type = InputType.tel
            value = phone
            required
            onChange = { event ->
                phone = event.target.value
            }
        }
        input {
            placeholder = Lang.getSelected().contact_form_email_input_placeholder
            type = InputType.email
            value = email
            required
            onChange = { event ->
                email = event.target.value
            }
        }
        textarea {
            id = "textareaMessage"
            placeholder = Lang.getSelected().contact_form_message_input_placeholder
            rows = 4
            value = message
            required
            draggable = false
            onChange = { event ->
                message = event.target.value
            }
        }
        input {
            className = ClassName("button orange")
            disabled = (!emailREGEX.toRegex().matches(email))
            type = InputType.submit
            value = Lang.getSelected().contact_form_submit_input_placeholder
        }
    }
}
