package view

import Lang
import component.JumpButton
import csstype.ClassName
import db.firebase
import kotlinx.browser.document
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.canvas
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.useState

@Serializable
data class ActionText(
        val id: Int,
        val text: String
)

const val matrixMode = "none"

val MainSection = FC<Props> {

    var x by useState(0)
    GlobalScope.launch(Dispatchers.Main) {
        delay(10000)
        x += 1
        if (x == Lang.getSelected().action_texts.size - 1) {
            x = 0
        }
    }
    div {
        id = "main-bg"
        div {
            id = "main"
            className = ClassName("split section")

            div {
                h1 {
                    span {
                        className = ClassName("main-title")
                        span {
                            +Lang.getSelected().main_title1
                            +" "
                        }
                        span {
                            +Lang.getSelected().main_title2
                        }
                    }
                }
                div {
                    + Lang.getSelected().main_description
                }
                div {
                    button {
                        className = ClassName("button orange")
                        +Lang.getSelected().main_button_contact_us
                        onClick = {
                            document.location?.href = "https://cexbit.com/contact"
                        }
                    }
                }
            }
            div {
                className = ClassName("main-hero")
                div {
                    className = ClassName("image-holder")
                    /*
                    img {
                        id = "phone-image"
                        alt = "phone"
                        src = "assets/mobile.png"
                    } */
                    div {
                        className = ClassName("phone-container")
                        div {
                            className = ClassName("phone")
                            div {
                                className = ClassName("front")
                            }
                            div {
                                className = ClassName("back")
                            }
                            div {
                                className = ClassName("left")
                            }
                            div {
                                className = ClassName("left-top")
                            }
                            div {
                                className = ClassName("left-bottom")
                            }
                            div {
                                className = ClassName("right")
                            }
                            div {
                                className = ClassName("right-top")
                            }
                            div {
                                className = ClassName("right-bottom")
                            }
                            div {
                                className = ClassName("shadow")
                            }
                        }
                    }
                }
            }
            canvas {
                id = "matrix"
                className = ClassName(matrixMode)
            }
        }
    }
}

