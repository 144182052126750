import component.ChatComponent
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import view.*

val App = FC<Props> {

    var preparedLang: String by useState("")
    val setLanguage = { input: String ->
        console.log("setLanguage: $input")
        preparedLang = input
        localStorage["lang"] = input
        Lang.select(input)
    }

    // initial load
    if (preparedLang === "") {
        // is already stored in localstorage lang user preference ?
        val langStorage = localStorage["lang"]
        console.log("localStorage[\"lang\"]: $langStorage")
        val langBrowser = window.navigator.language
        console.log("window.navigator.language: $langBrowser")
        val lang = langStorage ?: langBrowser
        console.log("using lang: $lang")
        setLanguage(lang)
    }

    document.onreadystatechange = {
        console.log("onreadystatechange...")
    }

    div {
        id = "app"
        Header {
            this.setLang = setLanguage
        }
        MainSection {
        }
        TopHexBackground {
            TechSection {
            }
            ServiceSection {
            }
        }
        PartnerSection {
            partners = Lang.getSelected().partners
        }
        ProductSection {
            products = Lang.getSelected().products
        }
//        JoinUsSection {
//        }
//        HiringSection {
//            hiringPositions = Lang.getSelected().hiring_positions
//        }
//        BottomHexBackground {
//            FAQSection {
//            }
            ContactSection {
            }
//            LocationSection {
//            }
//        }
        Footer {
        }
        ChatComponent {
        }
    }
}
